import router from './router/router';
import store from './store/store';
import { message } from 'ant-design-vue';
import { axiosPromiseArr } from './utils/fetchs.js';
import { initRoutes } from './router/router';

const allowList = ['/login', '/webview', '/aboutInfo', '/404', '/401', '/policyInfo'];

const allowList2 = ['/policyDetail', '/gardenDetail', '/serveDetail', '/serveAppDetail'];
router.beforeEach((to, from, next) => {
	let token = store.state.token;
	document.body.scrollTop = 0;
	console.log('to de ye mian ', to);
	axiosPromiseArr.forEach((ele, index) => {
		ele.cancel('我中断一个请求康康');
		delete axiosPromiseArr[index];
	});
	let rules = store.state.rules || [];
	console.log;
	let hadRules = true;
	if (!store.state.userInfo.is_admin) {
		hadRules = rules.indexOf(to.fullPath) >= 0;
	}
	if (allowList.indexOf(to.path) > -1) {
		if (to.meta.title) {
			console.log(to.meta.title);
			document.title = to.meta.title;
		}
		next();
	} else if (token) {
		if (to.path == '/index') {
			next();
		} else {
			// 检查是否具有权限
			if (hadRules || allowList2.indexOf(to.path) > -1) {
				if (to.meta.title) {
					document.title = to.meta.title;
				}
				next();
			} else {
				// 检查是没有权限还是没有页面
				console.log('chushi', initRoutes);
				let hadPage = false;

				const checkPage = (pages) => {
					for (let i = 0; i < pages.length; i++) {
						if (pages[i].path == to.path) {
							hadPage = true;
						}
						if (pages[i].children) {
							checkPage(pages[i].children);
						}
					}
				};
				checkPage(initRoutes);
				if (hadPage) {
					next({ path: '/401' });
				} else {
					next({ path: '/404' });
				}
			}
		}
	} else {
		document.title = '登录';
		next({ path: '/login' });
	}
});

router.afterEach((to, from) => {
	document.body.scrollTop = 0;
	let bodySrcollTop = document.body.scrollTop;
	if (bodySrcollTop !== 0) {
		document.body.scrollTop = 0;
		return;
	}
	let docSrcollTop = document.documentElement.scrollTop;
	if (docSrcollTop !== 0) {
		document.documentElement.scrollTop = 0;
	}
});
