<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style lang="less">
@import "@/static/css/style.less";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
#editor1,
#editor2 {
  height: 500px;
  border: 1px solid var(--ck-color-toolbar-border);
}
</style>
