<template>
	<div class="left-bar">
		 <a-menu
		      mode="inline"
			  :selectedKeys="[curKey]"
			  
		    >
		      <a-menu-item :key="index" v-for="(item,index) in curLeftMenu">
				<router-link :to="item.path">{{item.name}}</router-link>
		      </a-menu-item>
			  </a-menu>
	</div>
</template>

<script>
export default {
	name: 'sidebar',
	data() {
		return {
		};
	},
	computed: {
		curKey() {
			let a = -1;
			for(let i = 0; i< this.curLeftMenu.length; i++) {
				if(this.curLeftMenu[i].path == this.$route.fullPath) {
					a = i
					break
				}
			}
			return a
		},
		curLeftMenu() {
			let a = -1;
			let par = this.$route.meta.parent
			console.log('par::',par)
			let headerNav = this.$store.state.headerNav
			let sidebarList = this.$store.state.sidebarList
			for (let i = 0; i < headerNav.length; i++) {
				console.log('headerNav[i]', headerNav[i])
				if(headerNav[i].name == par) {
					a = i
					break
				}
			}
			let list = a == -1 ? [] : sidebarList[a]
			console.log('curLeftMenu::',list)
			return list
		}
	},
	watch:{

	},
	created() {
	},
	mounted() {},
	methods: {}
};
</script>

<style lang="less">
	.left-bar {
		position: fixed;
		left: 0;
		top: 64px;
		bottom: 0;
		width: 180px;
		background: #fff;
		box-shadow: 2px 0px 6px 0px rgba(0, 21, 41, 0.12);
	}
</style>
