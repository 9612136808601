import Vue from 'vue';
import Router from 'vue-router';
import Layout from '@/layout';
Vue.use(Router);
export const routes = [
	{
		path: '/gardenSwiper',
		component: () => import('@/view/garden/gardenSwiper'),
		name: 'GardenSwiper',
		meta: {
			title: '园区轮播',
			noCache: true,
			permission: '',
			parent: '园区招商'
		}
	},
	{
		path: '/gardenList',
		component: () => import('@/view/garden/gardenList'),
		name: 'GardenList',
		meta: {
			title: '园区列表',
			noCache: true,
			permission: '',
			parent: '园区招商'
		}
	},
	{
		path: '/hotGarden',
		component: () => import('@/view/garden/hotGarden'),
		name: 'HotGarden',
		meta: {
			title: '推荐园区',
			noCache: true,
			permission: '',
			parent: '园区招商'
		}
	},
	{
		path: '/gardenDetail',
		component: () => import('@/view/garden/gardenDetail'),
		name: 'GardenDetail',
		meta: {
			title: '园区详情',
			noCache: true,
			permission: '',
			parent: '园区招商'
		}
	},
	{
		path: '/policySwiper',
		component: () => import('@/view/policy/policySwiper'),
		name: 'PolicySwiper',
		meta: {
			title: '政策轮播',
			noCache: true,
			permission: '',
			parent: '政策服务'
		}
	},
	{
		path: '/policyList',
		component: () => import('@/view/policy/policyList'),
		name: 'PolicyList',
		meta: {
			title: '政策列表',
			noCache: true,
			permission: '',
			parent: '政策服务'
		}
	},
	{
		path: '/policyDetail',
		component: () => import('@/view/policy/policyDetail'),
		name: 'PolicyDetail',
		meta: {
			title: '政策详情',
			noCache: true,
			permission: '',
			parent: '政策服务'
		}
	},
	{
		path: '/serveBanner',
		component: () => import('@/view/serve/serveBanner'),
		name: 'ServeBanner',
		meta: {
			title: '产业服务',
			noCache: true,
			permission: '',
			parent: '产业服务'
		}
	},
	{
		path: '/serveSwiper',
		component: () => import('@/view/serve/serveSwiper'),
		name: 'ServeSwiper',
		meta: {
			title: '产业服务轮播',
			noCache: true,
			permission: '',
			parent: '产业服务'
		}
	},
	{
		path: '/serveList',
		component: () => import('@/view/serve/serveList'),
		name: 'ServeList',
		meta: {
			title: '产业服务列表',
			noCache: true,
			permission: '',
			parent: '产业服务'
		}
	},
	{
		path: '/serveDetail',
		component: () => import('@/view/serve/serveDetail'),
		name: 'ServeDetail',
		meta: {
			title: '产业服务详情',
			noCache: true,
			permission: '',
			parent: '产业服务'
		}
	},
	{
		path: '/userInfo',
		component: () => import('@/view/userInfo/userInfo'),
		name: 'UserInfo',
		meta: {
			title: '用户中心',
			noCache: true,
			permission: '',
			parent: '用户中心'
		}
	},
	{
		path: '/userList',
		component: () => import('@/view/userInfo/userList'),
		name: 'UserList',
		meta: {
			title: '用户列表',
			noCache: true,
			permission: '',
			parent: '用户中心'
		}
	},
	{
		path: '/userAppointment',
		component: () => import('@/view/userInfo/userAppointment'),
		name: 'UserAppointment',
		meta: {
			title: '预约看房',
			noCache: true,
			permission: '',
			parent: '用户中心'
		}
	},
	{
		path: '/userEntrust',
		component: () => import('@/view/userInfo/userEntrust'),
		name: 'UserEntrust',
		meta: {
			title: '需求委托',
			noCache: true,
			permission: '',
			parent: '用户中心'
		}
	},
	{
		path: '/homeSwiper',
		component: () => import('@/view/home/homeSwiper'),
		name: 'HomeSwiper',
		meta: {
			title: '首页轮播',
			noCache: true,
			permission: '',
			parent: '首页设置'
		}
	},
	{
		path: '/homeGarden',
		component: () => import('@/view/home/homeGarden'),
		name: 'HomeGarden',
		meta: {
			title: '首页推荐',
			noCache: true,
			permission: '',
			parent: '首页设置'
		}
	},
	{
		path: '/homeBanner',
		component: () => import('@/view/home/homeBanner'),
		name: 'HomeBanner',
		meta: {
			title: '产品服务',
			noCache: true,
			permission: '',
			parent: '首页设置'
		}
	},
	{
		path: '/setting',
		component: () => import('@/view/setting/setting'),
		name: 'Setting',
		meta: {
			title: '产业服务类型',
			noCache: true,
			permission: '',
			parent: '系统设置'
		}
	},
	{
		path: '/tagSetting',
		component: () => import('@/view/setting/tagSetting'),
		name: 'Setting',
		meta: {
			title: '标签',
			noCache: true,
			permission: '',
			parent: '系统设置'
		}
	},
	{
		path: '/video',
		component: () => import('@/view/video/video'),
		name: 'Video',
		meta: {
			title: '视频',
			noCache: true,
			permission: '',
			parent: '视频中心'
		}
	},
	{
		path: '/xieyi',
		component: () => import('@/view/setting/xieyi'),
		name: 'Xieyi',
		meta: {
			title: '用户协议',
			noCache: true,
			permission: '',
			parent: '系统设置'
		}
	},
	{
		path: '/about',
		component: () => import('@/view/setting/about'),
		name: 'About',
		meta: {
			title: '关于我们',
			noCache: true,
			permission: '',
			parent: '系统设置'
		}
	},
	{
		path: '/serveApp',
		component: () => import('@/view/serveApp/serveApp'),
		name: 'ServeApp',
		meta: {
			title: '服务工具',
			noCache: true,
			permission: '',
			parent: '服务工具'
		}
	},
	{
		path: '/serveAppClass',
		component: () => import('@/view/serveApp/serveAppClass'),
		name: 'ServeAppClass',
		meta: {
			title: '服务工具分类',
			noCache: true,
			permission: '',
			parent: '服务工具'
		}
	},
	{
		path: '/serveAppList',
		component: () => import('@/view/serveApp/serveAppList'),
		name: 'ServeAppList',
		meta: {
			title: '服务工具列表',
			noCache: true,
			permission: '',
			parent: '服务工具'
		}
	},
	{
		path: '/serveAppDetail',
		component: () => import('@/view/serveApp/serveAppDetail'),
		name: 'ServeAppDetail',
		meta: {
			title: '工具详情',
			noCache: true,
			permission: '',
			parent: '服务工具'
		}
	}
];

export const adminRoutes = [
	{
		path: '/jueseList',
		component: () => import('@/view/permision/jueseList'),
		name: 'jueseList',
		meta: {
			title: '角色列表',
			noCache: true,
			permission: '',
			parent: '员工管理'
		}
	},
	{
		path: '/yuangongList',
		component: () => import('@/view/permision/yuangongList'),
		name: 'YuangongList',
		meta: {
			title: '员工列表',
			noCache: true,
			permission: '',
			parent: '员工管理'
		}
	}
];
const index = {
	path: '/index',
	component: () => import('@/view/index/index'),
	name: 'Index',
	meta: {
		title: '园企宝平台',
		noCache: true,
		permission: '',
		parent: '首页'
	}
};
const threeRoutes = [];
export const initRoutes = [
	{
		path: '/',
		component: Layout,
		redirect: '/index',
		name: 'Index',
		meta: {
			title: '园企宝-后台',
			icon: 'location'
		},
		children: [index, ...routes, ...adminRoutes]
	},
	{
		path: '/login',
		component: () => import('@/view/login'),
		name: 'Login',
		meta: {
			title: '登录'
		}
	},
	{
		path: '/404',
		component: () => import('@/view/404'),
		name: '404',
		meta: {
			title: '页面未找到'
		}
	},
	{
		path: '/401',
		component: () => import('@/view/401'),
		name: '401',
		meta: {
			title: '暂无权限'
		}
	},
	{
		path: '/aboutInfo',
		component: () => import('@/view/aboutInfo'),
		name: 'AboutInfo',
		meta: {
			title: '关于我们'
		}
	},
	{
		path: '/webview',
		component: () => import('@/view/webview'),
		name: 'Webview',
		meta: {
			title: '隐私服务'
		}
	},
	{
		path: '/policyInfo',
		component: () => import('@/view/policyInfo'),
		name: 'PolicyInfo',
		meta: {
			title: '政策详情'
		}
	}
];
const createRouter = () =>
	new Router({
		mode: 'history', // require service support
		base: '/',
		scrollBehavior(to, from, savedPosition) {
			console.log('路由滚动', savedPosition);
			// return new Promise((resolve, reject) => {
			//     setTimeout(() => {
			// 		let a = {}
			// 		if(savedPosition) {
			// 			a = savedPosition
			// 		}else{
			// 			a =  {
			// 				x: 0,
			// 				y: 0
			// 			}
			// 		}
			//       resolve(a)
			//     }, 200)
			//   })
			// let a = {}
			// if(savedPosition) {
			// 	a = savedPosition
			// }else{
			// 	a =  {
			// 		x: 0,
			// 		y: 0
			// 	}
			// }
			// return a
		},
		routes: initRoutes
	});

const router = createRouter();

export function resetRouter() {
	const newRouter = createRouter();
	router.matcher = newRouter.matcher; // reset router
}
export default router;
